import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api';
import LoginContext from '../../context/login_context/LoginContext';
import { LineFour } from '../../components/charts/LineFour.chart';
import { OURLineFour } from '../../components/charts/OURLineFour.chart';

/** CONSTANTES */
let medidores = [
    {
        "ELEMENTO": "NIVEL",
        "MEDIDOR":"NIVEL_ESTANQUE_POZO"
    },
    {
        "ELEMENTO":"PRESION",
        "MEDIDOR":"P_LINEA_AGUA_POZO"
    }
]

export const Pozos = () => {

    const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);

    const [ListMedidores_, setListMedidores_] = useState([]);

    
    const GetListMedidores = async () => {
        let list_ = await GetListMEdidores({ empresa: LoginState.planta });
        // console.log(list_)
        let GranBulk = [];
        for (let flt = 0; flt < list_.length; flt++) {
            const flt_ = list_[flt];
            if(flt_.TIPO === "NIVEL" || flt_.TIPO === "PRESION"){
                let medidor = (flt_.MEDIDOR).filter(flt_it => flt_it.MEDIDOR === "P_LINEA_AGUA_POZO" || flt_it.MEDIDOR === "NIVEL_ESTANQUE_POZO");
                GranBulk.push(medidor[0])
            }
        }
        
        setListMedidores_(GranBulk);
    };

    useEffect(() => {
        GetListMedidores();
        return () => {
          GetListMedidores();
        };
    }, []);

    return (<>
        <HeaderMenu Pag_Sel={"/v2/pozos"} />
        <h4>Pozos</h4>
        <div className="container-fluid">
            <div className="row">
                {ListMedidores_.map((ulx, index) => (
                    <div className='col-12 text-center' key={index}>
                        <OURLineFour DATA={ulx} />
                    </div>
                ))}
            </div>
        </div>
    </>
    )
}
