import React, { useEffect, useLayoutEffect, useState } from 'react'

import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { UpdateDataColumn } from '../../API/UpdateDataColumn.api'
import { REST_HOUSR } from '../../router/Url'
import { LecturaNespraOurRange } from '../../API/LecturaNespraOurRange.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'
import { GetLecturas } from '../../API/OUR_Lecturas/GetLecturas.api'

export const OURLineFour = ({DATA}) => {
  history.forward()
  // console.log('LineFour')
  // console.log(DATA)
  let title = DATA.MEDIDOR
  let subTitle = DATA.TIPO
  let unidad_med = DATA.UNIDAD
  const [state, setstate] = useState()
  const [IsREnder, setIsREnder] = useState(false)
  // const [MinMaxDataRange, setMinMaxDataRange] = useState([]);
  const [DateMin, setDateMin] = useState('');
  const [DateMmax, setDateMmax] = useState('');

  useLayoutEffect( ( ) => {
    setstate(options)
    setIsREnder(true)
  },[])

  let UpdateChartNow = async () => {
    // console.log(state.series[0].data)
    let OneWeek = 604800000;
    let OneDay = 86400000;
    let OneHour = 3600000;
    let OneMinit = 60000

    let now = Math.floor((new Date).getTime())//segundos now
    let pasado = now - (OneDay);
    // console.log("=>",new Date(now),new Date(pasado))
    // console.log("=>",new Date(DateMin+":00"),new Date(DateMmax+":59"))
    let datas = await GetLecturas({
      medidor: DATA.MEDIDOR,
      sensor: DATA.SENSOR,
      planta: DATA.EMPRESA,
      ahora: DateMmax===""?"":((new Date(DateMmax+":59.000Z").getTime())),
      pasado: DateMin===""?pasado:((new Date(DateMin+":00.000Z").getTime()))
    })
    let g = []
    
    let arr_consumo = await datas.map(sss => {
      let {timestamp,valor} = sss
      return [timestamp+OneHour,valor]//timestamp-(OneHour*REST_HOUSR))*1000
    })
    // console.log(arr_consumo)
    g = await [{
      name: title+" "+DATA.ELEMENTO,
      data: arr_consumo
    }]
    
    await setstate({...state,["series"]:g})
    
  }

  useEffect(() => {
    UpdateChartNow()
    setInterval(() => {
      UpdateChartNow()
    }, 1000*60*5);
  
    return () => {
     UpdateChartNow() 
    }
  }, [])
  
  const options = {
    chart: {
      type: 'line',
      zoomType: 'x',
      height: '400px',
      resetZoomButton: {
        position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -30,
            text: "zooom"
        }
      }
    },
    lang: {
      date: [
          'Enero', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ]
    },
    title: {
        text: title,
        style: {
          // fontSize:'small',
          fontWeight: 'bold',
          fontFamily: 'monospace',
          color: 'black'
        }
    },
    subtitle: {
        text: subTitle,
        style: {
          // fontSize:'small',
          fontWeight: 'lighter',
          fontFamily: 'monospace',
          color: 'gray'
        }
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
            month: '%e. %b',
            year: '%b'
        },
        title: {
            text: ''
        }
    },
    legend: {
      align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },
    yAxis: {
        title: {
          text: `Consumos ${title} por ${unidad_med}`
        },
        // min: 0
    },
    tooltip: {
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(d_)
          return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '</b>';
        },
        // headerFormat: '<b>{series.name}</b><br>',
        // pointFormat: '{point.x}: {point.y:.2f} m³',
        // crosshairs: true,
        // shared: true
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 2
        }
      },
    },

    series: [{
      name: 'Flujometro',
      data:
          [
            [Date.UTC(2023, 0,  3), 0.39],
            [Date.UTC(2023, 0,  6), 0.39],
            [Date.UTC(2023, 0,  9), 0.39],
            [Date.UTC(2023, 0,  9), 0.99],
            [Date.UTC(2023, 0, 11), 0.19],
            [Date.UTC(2023, 0, 13), 0.99],
            [Date.UTC(2023, 0, 13), 0.39],
            [Date.UTC(2023, 0, 16), 0.39],
            [Date.UTC(2023, 0, 19), 0.39]
          ]
    }]
  }

  if(IsREnder){
    return (
      <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
          <div className='datemodalcum-chart_fourOUR'>
          <div className='div-dmf'>
            <input
              type='datetime-local'
              title='FECHA INICIO'
              // value={DateMin}
              className='input-fecha-filter'
              onChange={(e)=>setDateMin(e.target.value)}
              // min={MinMaxDataRange[0]}
              // max={MinMaxDataRange[1]}
            />
            <span className='txt-input-fecha-filter-fourOUR'>Fecha Inicio</span>
          </div>
          <div className='div-dmf'>
            <input
              type='datetime-local'
              title='FECHA INICIO'
              // value={DateMmax}
              className='input-fecha-filter'
              onChange={(e)=>setDateMmax(e.target.value)}
              // min={MinMaxDataRange[0]}
              // max={MinMaxDataRange[1]}
            />
            <span className='txt-input-fecha-filter-fourOUR'>Fecha Fin</span>
          </div>
          <div className='div-dmf'>
            <button
              disabled={DateMin===""||DateMmax===""?true:false}
              className='btn btn-info mt-1 pt-1'
              onClick={()=>UpdateChartNow()}//console.log(DateMin, DateMmax)}
            >buscar</button>
          </div>
        </div>
          </div>
        </div>
      </div>
        
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
      </>
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}
