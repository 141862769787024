import React, { useEffect } from 'react'
import LoginState from './context/login_context/LoginState'
import { Route, Routes } from 'react-router-dom'
import Login from './pages/login/Login.page'
import './App.css'
import { ProtectedRouter } from './components/Protected/ProtectedRouter'
import Main from './pages/session/Main.page'
import { Exportador } from './pages/session/Exportador.page'
import { ChartGrgid } from './pages/session/ChartGrgid.page'
import { CtrlTemp } from './pages/session/CtrlTemp.page'
import { ChartsElectric } from './pages/session/ChartsElectric.page'
import { IngresoTemp } from './pages/session/IngresoTemp.page'
import { ControlAccesoData } from './pages/session/ControlAccesoData.page'
import { AddUbications } from './pages/session/AddUbications.page'
import { FacturerConsume } from './pages/session/FacturerConsume.page'
import { DatosCaldera } from './pages/session/DatosCaldera.page'
import { FactureChart } from './pages/session/FactureChart.page'
import { FactureFileView } from './pages/session/FactureFileView.page'
import { SsGgMonitorFijoKiosko } from './pages/session/SsGgMonitorFijoKiosko.page'
import { ChartTemperatura } from './pages/session/ChartTemperatura.page'
import { ChartsHidrico } from './pages/session/ChartsHidrico.page'
import { SsGgMonitorFijo } from './pages/session/SsGgMonitorFijo.page'
import { IndicadorTablaFaenaCrud } from './pages/session/IndicadorTablaFaenaCrud.page'
import { OUR_graficTest } from './pages/session/OUR_graficTest.page'
import { OUR_GaugeTest } from './pages/session/OUR_GaugeTest.page'
import { SSGGMonitorPersonal } from './pages/session/SSGGMonitorPersonal.page'
import { OUR_BarChar_SickChiken } from './pages/session/OUR_BarChar_SickChiken.page'
import { ActualizadorMonitorFijo } from './pages/session/ActualizadorMonitorFijo.page'
import { Temperaturas } from './pages/views/Temperaturas.view'
import { Pozos } from './pages/views/Pozos.view'
import { RilesView } from './pages/session/RilesView.page'
import { RilesMonitor } from './pages/session/RilesMonitor.page.js'
import { BorrarView } from './pages/session/BorrarView.page.js'

function App() {
  useEffect(() => {
    console.log('ssggmbOIq8Fek8dLtg9laUA7GuYDuFMYlVKBvDI0skzN')
    console.log('rilesmbOIq8Fek8dLtg9laUA7GuYDuFMYlVKBvDI0skzN')
    // console.log(new Date(1718368792000))
  }, [])
  
  
  return (
    <LoginState>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/ssggmbOIq8Fek8dLtg9laUA7GuYDuFMYlVKBvDI0skzN' element={<SsGgMonitorFijoKiosko />} />
        <Route path='/rilesmbOIq8Fek8dLtg9laUA7GuYDuFMYlVKBvDI0skzN' element={<RilesMonitor />} />

        <Route path='/dashboard' element={
          <ProtectedRouter>
            <Main />
          </ProtectedRouter>
        }/>

        <Route path='/actualizalo' element={
          <ProtectedRouter>
            <ActualizadorMonitorFijo />
          </ProtectedRouter>
        }/>

        <Route path='/ourgrafictest' element={
          <ProtectedRouter>
            <OUR_graficTest />
          </ProtectedRouter>
        }/>

        <Route path='/polloenfermo' element={
          <ProtectedRouter>
            <OUR_BarChar_SickChiken />
          </ProtectedRouter>
        }/>

        <Route path='/ourgaugetest' element={
          <ProtectedRouter>
            <OUR_GaugeTest />
          </ProtectedRouter>
        }/>
        
        {/* BORRAR */}
        <Route path='/borrar' element={
          <ProtectedRouter>
            <BorrarView />
          </ProtectedRouter>
        }/>

        <Route path='/ssggpersonal' element={
          <ProtectedRouter>
            <SSGGMonitorPersonal />
          </ProtectedRouter>
        }/>

        <Route path='/exportar' element={
          <ProtectedRouter>
            <Exportador />
          </ProtectedRouter>
        }/>
        <Route path='/calchart' element={
          <ProtectedRouter>
            <ChartGrgid />
          </ProtectedRouter>
        }/>
        {/* monitor fijo faena */}
        <Route path='/fijofijo' element={
          <ProtectedRouter>
            <SsGgMonitorFijo />
          </ProtectedRouter>
        }/>
        <Route path='/ctrl_temp' element={
          <ProtectedRouter>
            <CtrlTemp />
          </ProtectedRouter>
        }/>
        <Route path='/ingreso_temp' element={
          <ProtectedRouter>
            <IngresoTemp />
          </ProtectedRouter>
        }/>
        <Route path='/electric' element={
          <ProtectedRouter>
            <ChartsElectric />
          </ProtectedRouter>
        }/>
        <Route path='/tablacrud' element={
          <ProtectedRouter>
            <IndicadorTablaFaenaCrud />
          </ProtectedRouter>
        }/>
        <Route path='/hidric' element={
          <ProtectedRouter>
            <ChartsHidrico />
          </ProtectedRouter>
        }/>
        <Route path='/temperaturas' element={
          <ProtectedRouter>
            <ChartTemperatura />
          </ProtectedRouter>
        }/>
        <Route path='/accesos' element={
          <ProtectedRouter>
            <ControlAccesoData />
          </ProtectedRouter>
        }/>
        <Route path='/addubi' element={
          <ProtectedRouter>
            <AddUbications />
          </ProtectedRouter>
        }/>
        <Route path='/ifactura' element={
          <ProtectedRouter>
            <FacturerConsume />
          </ProtectedRouter>
        }/>
        <Route path='/chartfactura' element={
          <ProtectedRouter>
            <FactureChart />
          </ProtectedRouter>
        }/>
        <Route path='/viewfactura' element={
          <ProtectedRouter>
            <FactureFileView />
          </ProtectedRouter>
        }/>
        <Route path='/icaldera' element={
          <ProtectedRouter>
            <DatosCaldera />
          </ProtectedRouter>
        }/>
        <Route path='/riless' element={
          <ProtectedRouter>
            <RilesView />
          </ProtectedRouter>
        }/>

        {/* Graficos con datos propios */}
        <Route path='/v2/temperaturas' element={
          <ProtectedRouter>
            <Temperaturas />
          </ProtectedRouter>
        }/>
        <Route path='/v2/pozos' element={
          <ProtectedRouter>
            <Pozos />
          </ProtectedRouter>
        }/>

        <Route path='*' element={<Login />} />
        
      </Routes>
    </LoginState>
  )
}

export default App