import React, { useEffect, useLayoutEffect, useState } from 'react'

import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import highchartsExporting  from 'highcharts/modules/exporting'
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official'
import { UpdateDataColumn } from '../../API/UpdateDataColumn.api'
import { REST_HOUSR } from '../../router/Url'
import { LecturaNespraOurRange } from '../../API/LecturaNespraOurRange.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'
import { GetLecturas } from '../../API/OUR_Lecturas/GetLecturas.api'

const Limites = [
  {MEDIDOR: "TRAFO 2 (TSDM)", LIMITE:150},
  {MEDIDOR: "TRAFO 3 (TSDM)", LIMITE:150},
  {MEDIDOR: "TRAFO 4 (TSDM)", LIMITE:1300},
  {MEDIDOR: "TRAFO 7 (TSDM)", LIMITE:80},
]

highchartsExporting(Highcharts)
highchartsExportData(Highcharts)
// highchartsAccessibility(Highcharts)

export const LineFour = ({DATA}) => {
  // console.log('LineFour')
  // console.log(DATA)
  let title = DATA.MEDIDOR
  let subTitle = DATA.TIPO
  let unidad_med = DATA.UNIDAD
  const [state, setstate] = useState()
  const [IsREnder, setIsREnder] = useState(false)
  // const [MinMaxDataRange, setMinMaxDataRange] = useState([]);
  const [DateMin, setDateMin] = useState('');
  const [DateMmax, setDateMmax] = useState('');

  const [Limite, setLimite] = useState(0);
  const addedLimite = async () => {
    let limite = Limites.filter(fl => fl.MEDIDOR === DATA.MEDIDOR)
    setLimite(limite[0]?.LIMITE||0)
    // console.log(limite[0].LIMITE)
    return Number(limite[0]?.LIMITE||0)
  }

  useLayoutEffect( ( ) => {
    const Renderize = async () => {
      await addedLimite()
      await setstate(options)
      await setIsREnder(true)
    }
    Renderize()
    /** carga una unica vex el limite sugerido */
  },[])

  let UpdateChartNow = async () => {
    // console.log(state.series[0].data)
    let OneWeek = 604800;
    let OneDay = 86400;
    let OneHour = 3600;
    let OneMinit = 60

    let now = Math.floor((new Date).getTime()/1000)//segundos now
    let pasado = now - (OneWeek*1);
    // console.log("=>",pasado)
    let datas = []
    let arr_consumo = []
    if(DATA.CONTRATO === "MARCUXO"){
      console.log(DATA.CONTRATO)
      datas = await GetLecturas({
        medidor: DATA.MEDIDOR,
        sensor: DATA.SENSOR,
        planta: DATA.EMPRESA,
        ahora: DateMmax===""?now*1000:((new Date(DateMmax+":59").getTime())),
        pasado: DateMin===""?pasado*1000:((new Date(DateMin+":00").getTime()))
      })
      arr_consumo = await datas.map(sss => {
        let {timestamp,valor} = sss
        return [timestamp,valor]
      })
    }else{
      datas = await LecturaNespraOurRange({
        medidor: DATA.MEDIDOR,
        sensor: DATA.SENSOR,
        planta: DATA.EMPRESA,
        ahora: DateMmax===""?now:((new Date(DateMmax+":59").getTime())/1000),
        pasado: DateMin===""?pasado:((new Date(DateMin+":00").getTime())/1000)
      })
      arr_consumo = await datas.map(sss => {
        let {timestamp,valor} = sss
        return [(timestamp-(OneHour*REST_HOUSR))*1000,valor]
      })
    }
    // console.log(datas)
    let g = []
    // LOGICA PARA GENERAR DATOS EN CASO DE SER MEDIDOR DE NESPRA
    
    // g = await [{
    //   name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
    //   data: arr_consumo
    // }]

    // let h = {
    //   title: {
    //     text: `Consumos ${title} por ${unidad_med}`
    //   },
    //   plotLines: [{
    //     color: 'red',
    //     width: 1,
    //     value: addedLimite(),
    //     animation: {
    //         duration: 1000,
    //         defer: 4000
    //     },label: {
    //       text: '',
    //       align: 'right',
    //       x: -130,
    //       style: {
    //         color: 'red',
    //         fontWeight: 'bolder',
    //         fontFamily: 'monospace',
    //       }
    //     },
    //   }],
    //   // min: 0
    // }

    let i = await {
      chart: {
        type: 'line',
        zoomType: 'x',
        height: '400px',
        resetZoomButton: {
          position: {
              // align: 'right', // by default
              // verticalAlign: 'top', // by default
              x: 0,
              y: -30,
              text: "zooom"
          }
        }
      },
      lang: {
        date: [
            'Enero', 'Février', 'Mars', 'Avril',
            'Mai', 'Juin', 'Juillet', 'Août',
            'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ]
      },
      title: {
          text: title,
          style: {
            // fontSize:'small',
            fontWeight: 'bold',
            fontFamily: 'monospace',
            color: 'black'
          }
      },
      subtitle: {
          text: subTitle,
          style: {
            // fontSize:'small',
            fontWeight: 'lighter',
            fontFamily: 'monospace',
            color: 'gray'
          }
      },
      xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the year
              month: '%e. %b',
              year: '%b'
          },
          title: {
              text: ''
          }
      },
      legend: {
        align: 'left',
          verticalAlign: 'top',
          borderWidth: 0
      },
      yAxis: {
          title: {
            text: `Consumos ${title} por ${unidad_med}`
          },
          plotLines: [{
            color: 'red',
            width: 1,
            value: await addedLimite(),
            animation: {
                duration: 1000,
                defer: 4000
            },label: {
              text: await addedLimite()===0?'':`${await addedLimite()}${unidad_med}`,
              align: 'right',
              x: -130,
              style: {
                color: 'red',
                fontWeight: 'bolder',
                fontFamily: 'monospace',
              }
            },
          }],
          // min: 0
      },
      tooltip: {
          formatter: function() {
            let d_ = new Date(this.x)
            return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '</b>';
          },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            radius: 2
          },
        },
      },
      series: [{
        name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
        data: arr_consumo
      }],
    //   exporting: {
    //     enabled: true,
    //     menuItemDefinitions: {
    //         // Custom definition
    //         label: {
    //             onclick: function () {
    //                 this.renderer.label(
    //                     'You just clicked a custom menu item',
    //                     100,
    //                     100
    //                 )
    //                     .attr({
    //                         fill: '#a4edba',
    //                         r: 5,
    //                         padding: 10,
    //                         zIndex: 9999999999999999999999999999999999999999999999999999999999
    //                     })
    //                     .css({
    //                         fontSize: '1.5em'
    //                     })
    //                     .add();
    //             },
    //             text: 'Show label'
    //         }
    //     },
    //     // buttons: {
    //     //     contextButton: {
    //     //         menuItems: ['downloadPNG', 'downloadSVG', 'separator', 'label']
    //     //     }
    //     // }
    // }
    }
    
    // await setstate({...state,["series"]:g,["yAxis"]:h})
    await setstate(i)
    
  }

  useEffect(() => {
    UpdateChartNow()
    setInterval(() => {
      UpdateChartNow()
    }, 1000*60*30);
  
    return () => {
     UpdateChartNow() 
    }
  }, [])
   
  const options = {
    chart: {
      type: 'line',
      zoomType: 'x',
      height: '400px',
      resetZoomButton: {
        position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -30,
            text: "zooom"
        }
      }
    },
    lang: {
      date: [
          'Enero', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ]
    },
    title: {
        text: title,
        style: {
          // fontSize:'small',
          fontWeight: 'bold',
          fontFamily: 'monospace',
          color: 'black'
        }
    },
    subtitle: {
        text: subTitle,
        style: {
          // fontSize:'small',
          fontWeight: 'lighter',
          fontFamily: 'monospace',
          color: 'gray'
        }
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
            month: '%e. %b',
            year: '%b'
        },
        title: {
            text: ''
        }
    },
    legend: {
      align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },
    yAxis: {
        title: {
          text: `Consumos ${title} por ${unidad_med}`
        },
        plotLines: [{
          color: 'red',
          width: 1,
          value: Limite || 0,
          animation: {
              duration: 1000,
              defer: 4000
          },label: {
            text: '',
            align: 'right',
            x: -130,
            style: {
              color: 'red',
              fontWeight: 'bolder',
              fontFamily: 'monospace',
            }
          },
        }],
        // min: 0
    },
    tooltip: {
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(d_)
          return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '</b>';
        },
        // headerFormat: '<b>{series.name}</b><br>',
        // pointFormat: '{point.x}: {point.y:.2f} m³',
        // crosshairs: true,
        // shared: true
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 2
        }
      },
    },

    series: [{
      name: 'Flujometro',
      data:
          [
            [Date.UTC(2023, 0,  3), 0.39],
            [Date.UTC(2023, 0,  6), 0.39],
            [Date.UTC(2023, 0,  9), 0.39],
            [Date.UTC(2023, 0,  9), 0.99],
            [Date.UTC(2023, 0, 11), 0.19],
            [Date.UTC(2023, 0, 13), 0.99],
            [Date.UTC(2023, 0, 13), 0.39],
            [Date.UTC(2023, 0, 16), 0.39],
            [Date.UTC(2023, 0, 19), 0.39]
          ]
    }]
  }

  if(IsREnder){
    return (
      <>
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
          <div className='datemodalcum-chart'>
            <div className='div-dmf'>
              <input
                type='datetime-local'
                title='FECHA INICIO'
                // value={DateMin}
                className='input-fecha-filter'
                onChange={(e)=>setDateMin(e.target.value)}
                // min={MinMaxDataRange[0]}
                // max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Inicio</span>
            </div>
            <div className='div-dmf'>
              <input
                type='datetime-local'
                title='FECHA INICIO'
                // value={DateMmax}
                className='input-fecha-filter'
                onChange={(e)=>setDateMmax(e.target.value)}
                // min={MinMaxDataRange[0]}
                // max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Fin</span>
            </div>
            <div className='div-dmf'>
              <button
                disabled={DateMin===""||DateMmax===""?true:false}
                className='btn btn-info mt-1 pt-1'
                onClick={()=>UpdateChartNow()}//console.log(DateMin, DateMmax)}
              >buscar</button>
            </div>
          </div>

      </>
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}
